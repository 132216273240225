<template>
  <div>
    <div class="head_title_wrapper">
      <div class="head_title">羽林数据中台</div>
      <div class="head_des">为企业电商打造的数字推广平台
      </div>
      <div class="head_content">
        构建H5智能商城，精细化前端一手掌握客户数据，品牌推广、客户管理、订单完成清晰条理。 ROI实时分析客户行为，不同的数据算法实时推荐最优数据，不再用心算，不靠感觉做决策。 从数字推广到ROI评估，数据算法推荐形成完美运营方案。

      </div>
    </div>
    <div class="container">
      <div class="three">
        <div class="three_title">
          三大核心利器解决企业运营的难题

        </div>
        <div class="thress_des">
          电商运营是一个数字化推广和运营的过程，由于运营过程的冗长繁杂，不能把握核心往往容易沦为打杂， 羽林数据中台围绕提升“数字运营战斗力”亮出三大利器，为企业运营开拓局面。

        </div>
        <div class="swiper_wrapper" @mouseover="enter('SW_swiper')" @mouseout="leave('SW_swiper')">
          <div class="swiper_flex">
            <div :class="index == SW_activeIndex ? 'active_slide_color' : ''" v-for="(item,index) in SW_list" :key="item" @click="SW_active_slide(index)">{{item}}</div>
          </div>
          <div class="swiper-container swiper-container-1 swiper-container-web" ref='swiperContainerWeb'>
            <div class="swiper-wrapper swiper-wrapper_web">
              <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/91a4f3c4387702299483555657/f0.png" alt=""></div>
              <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/c35e9b2f387702299531076023/f0.png" alt=""></div>
              <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/91a56699387702299483556051/f0.png" alt=""></div>
            </div>
          </div>
          <div class="swiper-container swiper-container-1 swiper-container-mini" ref='swiperContainerMini'>
            <div class="swiper-wrapper swiper-wrapper_web">
              <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/46eba2a3387702299482641976/f0.png" alt=""></div>
              <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/91a4ef1e387702299483555525/f0.png" alt=""></div>
              <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/916eae1a387702299483525722/f0.png" alt=""></div>
            </div>
          </div>
        </div>
        <div class="all_link">
          <div class="all_link_title">羽林数据中台完成从获客、运营到提高利润的全链路支撑
          </div>

          <div class="all_link_title all_link_title_mini">羽林数据中台<br />完成从获客、运营到提高利润的全链路支撑
          </div>

          <div class="all_link_swiper" @mouseover="enter('data_swiper')" @mouseout="leave('data_swiper')">
            <div class="link_list">
              <div :class="index == data_activeIndex ? 'link_table active_color' : 'link_table'" v-for="(item,index) in data_list" :key="index" @click="data_active_slide(index)">
                <p class="active_p">{{item.p}}
                </p>
                <img class="" :src="index == data_activeIndex ? require('../../assets/'+item.img1) : require('../../assets/'+item.img2)" alt="">
                <!-- <img class="show hideimg " src="../../assets/img/center/shangcheng.png" alt=""> -->
                <div class="active_p">
                  {{item.text1}} <br v-show="item.text2"> {{item.text2 ? item.text2 : ''}}
                </div>
              </div>
            </div>
            <!-- Swiper -->
            <div class="swiper-container swiper-container-2 link_table_swiper" ref="datarWrapper">
              <div class="swiper-wrapper">
                <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/916eb307387702299483525879/f0.png" alt=""></div>
                <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/916f20d2387702299483526110/f0.gif" alt=""></div>
                <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/46ec1ddc387702299482642563/f0.png" alt=""></div>
                <div class="swiper-slide"><img src="http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/46ec2179387702299482642614/f0.png" alt=""></div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 右侧悬浮框 -->
    <RightSuspension></RightSuspension>
  </div>
</template>

<script>
import Swiper from '@/utils/swiper'
import RightSuspension from '@/components/right_suspension'

export default {
  components: { RightSuspension },

  data() {
    return {
      SW_swiper: null,
      data_swiper: null,
      MINI_swiper: null,
      SW_activeIndex: '',
      data_activeIndex: '',
      SW_list: ['利器一：智能推广 ', '利器二：数据算法 ', '利器三：ROI推荐 '],
      data_list: [
        {
          p: '获客、固客方便',
          img1: 'img/center/shangcheng.png',
          img2: 'img/index/huokefangbian.png',
          text1: 'H5智能商城',
          text2: '（整合营销推广数字化）'
        },
        {
          p: '成交更容易',
          img1: 'img/center/tuijian.png',
          img2: 'img/index/roizhinneg.png',
          text1: 'ROI智能推荐'
        },
        {
          p: '运营更简单',
          img1: 'img/center/daima.png',
          img2: 'img/index/suanfashuju.png',
          text1: '数据算法'
        },
        {
          p: '管理更简洁',
          img1: 'img/center/kanban.png',
          img2: 'img/index/jingyingkanban.png',
          text1: '经营看板'
        }
      ]
    }
  },
  created() {},
  mounted() {
    let _this = this
    this.SW_swiper = new Swiper(this.$refs.swiperContainerWeb, {
      // autoplay: true,
      on: {
        slideChange: function() {
          _this.SW_activeIndex = this.activeIndex
        }
      }
    })

    this.MINI_swiper = new Swiper(this.$refs.swiperContainerMini, {
      // autoplay: true,
      on: {
        slideChange: function() {
          _this.SW_activeIndex = this.activeIndex
        }
      }
    })

    this.data_swiper = new Swiper(this.$refs.datarWrapper, {
      // autoplay: true,
      on: {
        slideChange: function() {
          _this.data_activeIndex = this.activeIndex
        }
      }
    })
  },
  methods: {
    enter(key) {
      this[key].autoplay.stop()
    },
    leave(key) {
      this[key].autoplay.run()
    },
    SW_active_slide(e) {
      this.SW_activeIndex = e
      if(document.documentElement.getBoundingClientRect().width <= 750){
        this.MINI_swiper.slideTo(e, 1000, false)
        return
      }
      this.SW_swiper.slideTo(e, 1000, false)
    },
    data_active_slide(e) {
      this.data_activeIndex = e
      this.data_swiper.slideTo(e, 1000, false)
    }
  }
}
</script>

<style src='../../assets/css/swiper.css'></style>

<style lang="less" scoped>
.head_title_wrapper {
  background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/919363e1387702299483546591/f0.png');
  background-size: 100% 100%;
  padding-top: 80px;
  padding-bottom: 162px;
  margin-bottom: 112px;
  .head_title {
    font-size: 36px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 26px;
    text-align: center;
  }

  .head_des {
    text-align: center;
    font-size: 18px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 36px;
  }

  .head_content {
    width: 570px;
    margin: 0 auto;
    font-size: 14px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
  }
}

.three {
  .three_title {
    font-size: 28px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #1740df;
    text-align: center;
    margin-bottom: 60px;
  }

  .thress_des {
    width: 816px;
    margin: 0 auto;
    font-size: 18px;
    font-family: GraublauWeb;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    margin-bottom: 68px;
  }

  .swiper_wrapper {
    height: 470px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(27, 71, 251, 0.2);
    margin-bottom: 184px;
    position: relative;
    .swiper_flex {
      display: flex;
      align-items: center;
      position: absolute;
      width: 900px;
      height: 60px;
      top: -30px;
      left: 50%;
      margin-left: -450px;
      z-index: 100;

      .active_slide_color {
        background: linear-gradient(150deg, #317bff, #1740df) !important;
        color: #ffffff !important;
      }

      & > div {
        height: 60px;
        width: 300px;
        text-align: center;
        box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.2);
        font-size: 18px;
        font-family: GraublauWeb;
        font-weight: 400;
        color: #333333;
        line-height: 60px;
        background: #ffffff;
        cursor: pointer;
      }
    }

    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .swiper-container-mini {
      display: none;
    }
  }

  .all_link {
    .all_link_title {
      font-size: 28px;
      font-family: GraublauWeb;
      font-weight: 400;
      color: #1740df;
      margin-bottom: 80px;
      text-align: center;
      margin-bottom: 205px;
    }

    .all_link_title_mini {
      display: none;
    }

    .all_link_swiper {
      margin-bottom: 226px;
      position: relative;
      .link_list {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 960px;
        position: absolute;
        top: -125px;
        z-index: 999;
        left: 50%;
        margin-left: -480px;

        .active_color {
          color: #ffffff !important;
          background: #1740df !important;

          .active_p {
            color: #ffffff !important;
          }
        }

        .link_table {
          width: 240px;
          height: 170px;
          background: #ffffff;
          box-shadow: 0px 1px 8px 0px rgba(0, 48, 101, 0.1);
          /* border-radius: 10px; */
          padding-top: 24px;
          margin-right: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          cursor: pointer;

          & > p {
            font-size: 20px;
            font-family: GraublauWeb;
            font-weight: 400;
            color: #333333;
            margin-bottom: 20px;
          }

          & > img {
            width: 62px;
            height: 62px;
            margin-bottom: 16px;
          }

          & > div {
            display: flex;
            align-items: center;
            height: 36px;
            font-size: 14px;
            font-family: GraublauWeb;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            text-align: center;
            margin-bottom: 14px;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media all and (max-width: 1080px) {
  .head_title_wrapper {
    margin-bottom: 0;
    background-image: url('http://1300718258.vod2.myqcloud.com/94092fe5vodcq1300718258/919367a1387702299483546654/f0.png');
    background-size: 100% 85%;
    background-repeat: no-repeat;
    .three_title {
      font-size: 23px;
    }
    .head_content {
      width: auto;
      font-size: 18px;
      padding: 0 20px;
      font-family: GraublauWeb;
    }
  }

  .container {
    .three {
      .three_title {
        font-size: 23px;
      }
      .thress_des {
        width: auto;
        padding: 0 20px;
        margin-bottom: 100px;
      }
      .swiper_wrapper {
        height: auto;
        box-shadow: unset;
        margin-bottom: 139px;
      }
      .swiper_flex {
        /* display: none */
        width: 100%;
        left: 0;
        top: -48px;
        margin-left: 0;
        div {
          width: 33.3%;
          font-size: 12px;
        }
      }

      .swiper-slide > img {
        width: 100%;
      }

      .swiper-container-web {
        display: none;
      }

      .swiper-container-mini {
        display: block;
      }

      .all_link {
        .all_link_title {
          display: none;
        }
        .all_link_title_mini {
          display: block;
          line-height: 28px;
          font-size: 19px;
          margin-bottom: 64px;
        }
        .all_link_swiper {
          margin-bottom: 0;
          .link_list {
            /* display: none */
            width: 100%;
            left: 0;
            top: -155px;
            margin-left: 0;
            .link_table {
              width: 25%;
              padding-top: 0;
              img {
                width: 40px;
                height: 40px;
              }
              p,
              div {
                font-size: 12px;
              }
              div {
                display: flex;
                align-items: center;
                height: 40px;
                line-height: 20px;
                margin-bottom: 0;
              }
            }
          }

          .link_table_swiper {
            margin-top: 200px;
            .swiper-slide > img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>